import { Fragment } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
// import EvaluatorFinalize from './evaluatorfinalize';
import { ScoringPanelSlabInput } from './evaluatorslabinput';
import GetFullRoutine from '../../apollo/queries/GetFullRoutine.graphql';
import {
  newestScoreOfType,
  useRoutineScore,
  convertMillipointsToDisplay,
} from '../../utilities/scoring';
import { useEvalConfig, useDisplayConfig } from './hooks';
import './scoringpanel.css';

export default function ScoringPanelSlab({
  team,
  sessionId,
  routineId,
  judgeType,
  setSelectedType,
  selectedType,
}) {
  const { data, error } = useQuery(GetFullRoutine, {
    variables: { id: routineId },
    skip: !routineId || routineId === 'FAKE-ROUTINE-ID',
  });

  // console.log('scoringPanel render');

  const {
    dPanel,
    ePanel,
    fPanel,
    jPanel,
    svPanel,
    nDeduct,
    stickBonus,
    roles,
    // startValueOptional = false,
  } = useEvalConfig();
  const routine = data?.getRoutine;
  const routineScore = useRoutineScore();
  const displayConfig = useDisplayConfig();
  const extraPanelWidth = +nDeduct + stickBonus + ((nDeduct || stickBonus) & 1);
  // const evalPanelWidth = 9 - extraPanelWidth;
  const roleCheck = roles?.[judgeType?.toUpperCase()] || false;
  const { scoreTotal, subScoresComplete } = routineScore({
    scores: { items: routine?.scores?.items },
  });

  console.log(scoreTotal);

  const { scorePrecision } = displayConfig;

  if (error) return <span>{error.message}</span>;
  if (!routine) return null;

  const judgeTypeRoles =
    (roles && judgeType && roleCheck && Object.keys(roleCheck)) || [];

  // const hasPendingScores = routine.scores.items.some(
  //   (score) => score.id === 'FAKE-SCORE-ID'
  // );

  // const hasScoresFor = (panel) =>
  //   (panel &&
  //     panel.every(({ type }) =>
  //       routine.scores.items.some((score) => score.type === type)
  //     )) ||
  //   !panel;

  // const scoreField = () => {
  //   if (hasPendingScores) {
  //     return <span>Sending...</span>;
  //   }

  //   if (roleCheck && !roles?.[judgeType.toUpperCase()]?.['finalize']) {
  //     return null;
  //   }

  //   if (
  //     hasScoresFor(ePanel) &&
  //     hasScoresFor(dPanel) &&
  //     hasScoresFor(jPanel) &&
  //     (hasScoresFor(svPanel) || startValueOptional)
  //   ) {
  //     return (
  //       <EvaluatorFinalize
  //         scores={routine.scores.items}
  //         team={team}
  //         routineData={routine}
  //       />
  //     );
  //   }

  //   return <span>On Eval</span>;
  // };

  const evalField = ({ type, i }) => {
    const score = newestScoreOfType(routine.scores.items, type);

    const showType =
      !judgeType ||
      !roleCheck ||
      (judgeTypeRoles.includes(type) &&
        roles?.[judgeType.toUpperCase()]?.[type]);

    if (!showType) {
      return null;
    }
    return (
      <ScoringPanelSlabInput
        type={type}
        routineId={routineId}
        sessionId={sessionId}
        score={score}
        key={`eval-input-${type}-${i}`}
        setSelectedType={setSelectedType}
        isSelected={type === selectedType}
      />
    );
  };

  const finalField = () => {
    return (
      <Button
        className={selectedType === 'FINAL' ? 'selected' : ''}
        onClick={() => setSelectedType('FINAL')}
        variant="secondary-outline"
      >
        <span className="superScript">Final Score</span>
        {scoreTotal || (scoreTotal === 0 && subScoresComplete)
          ? convertMillipointsToDisplay(scoreTotal, scorePrecision)
          : '--'}
      </Button>
    );
  };

  console.log(routine);

  return (
    <div className="">
      <Row className="slabAthlete">{routine.athlete.name}</Row>
      <Row className="finalScore">{finalField()}</Row>
      <Row>
        <Col className="evalPanel">
          {svPanel?.map(({ type }, i) => {
            return evalField({ type, i });
          })}
          {dPanel?.map(({ type }, i) => {
            return evalField({ type, i });
          })}
          {fPanel?.map(({ type }, i) => {
            return evalField({ type, i });
          })}
        </Col>
        <Col className="evalPanel">
          {jPanel?.map(({ type }, i) => {
            return evalField({ type, i });
          })}
          {ePanel?.map(({ type }, i) => {
            return evalField({ type, i });
          })}
        </Col>
      </Row>
      <Row>
        {extraPanelWidth ? (
          <>
            <Col className="evalExtras">
              {nDeduct &&
                (dPanel.length !== 0 || svPanel.length !== 0) &&
                evalField({ type: 'ND', i: 0 })}
            </Col>
            <Col className="evalExtras">
              {stickBonus &&
                dPanel.length !== 0 &&
                evalField({ type: 'SB', i: 0 })}
            </Col>
          </>
        ) : null}
        {/*        <Col className="vCenter tscore" xs={3}>
          {scoreField()}
        </Col>*/}
      </Row>
    </div>
  );
}

export function DisabledScoringPanelSlab({
  onAir,
  onEval,
  onStandby,
  routineId,
  judgeType,
}) {
  const { data, error } = useQuery(GetFullRoutine, {
    variables: { id: routineId },
    skip: !routineId || routineId === 'FAKE-ROUTINE-ID',
  });
  const {
    ePanel,
    dPanel,
    fPanel,
    jPanel,
    svPanel,
    nDeduct,
    stickBonus,
    roles,
  } = useEvalConfig();

  if (error) return <span>{error.message}</span>;

  const routine = data?.getRoutine;
  const extraPanelWidth = +nDeduct + stickBonus + ((nDeduct || stickBonus) & 1);
  const evalPanelWidth = 9 - extraPanelWidth;
  const roleCheck = roles?.[judgeType?.toUpperCase()] || false;
  const judgeTypeRoles =
    (roles && judgeType && roleCheck && Object.keys(roleCheck)) || [];

  const evalButton = ({ type, i }) => {
    const score = routine && newestScoreOfType(routine.scores.items, type);
    const showType =
      !judgeType ||
      !roleCheck ||
      (judgeTypeRoles.includes(type) &&
        roles?.[judgeType.toUpperCase()]?.[type]);

    if (!showType) {
      return null;
    }

    return (
      <Button
        className={type}
        variant="secondary"
        disabled={true}
        key={`eval-button-${type}-${i}`}
      >
        {type}
        {!!score ? <div className="lockIndicator" /> : null}
      </Button>
    );
  };

  return (
    <div className="evaluator">
      <Row style={{ height: '100%' }}>
        <Col className="evalPanel" xs={evalPanelWidth}>
          {svPanel?.map(({ type }, i) => {
            return (
              <Fragment key={i}>
                {evalButton({ type, i })}
                {evalButton({ type: jPanel?.[i]?.type, i })}
              </Fragment>
            );
          })}
          {!svPanel &&
            jPanel?.map(({ type }, i) => {
              return evalButton({ type, i });
            })}
          {fPanel?.map(({ type }, i) => {
            return evalButton({ type, i });
          })}
          {ePanel?.map(({ type }, i) => {
            return evalButton({ type, i });
          })}
          {dPanel?.map(({ type }, i) => {
            return evalButton({ type, i });
          })}
        </Col>
        {extraPanelWidth ? (
          <Col className="evalExtras" xs={extraPanelWidth}>
            {
              nDeduct &&
                (dPanel.length !== 0 || svPanel.length !== 0) &&
                evalButton({ type: 'ND', i: 0 })
              // (
              //   <Button className="ND" variant="secondary" disabled={true}>
              //     ND
              //     {routine &&
              //     !!newestScoreOfType(routine.scores.items, 'ND') &&
              //     newestScoreOfType(routine.scores.items, 'ND')?.value > 0 ? (
              //       <div className="lockIndicator" />
              //     ) : null}
              //   </Button>
              // )
            }
            {
              stickBonus &&
                dPanel.length !== 0 &&
                evalButton({ type: 'SB', i: 0 })
              // (
              //   <Button className="SB" variant="secondary" disabled={true}>
              //     SB
              //     {routine &&
              //     !!newestScoreOfType(routine.scores.items, 'SB') &&
              //     newestScoreOfType(routine.scores.items, 'SB')?.value > 0 ? (
              //       <div className="lockIndicator" />
              //     ) : null}
              //   </Button>
              // )
            }
          </Col>
        ) : null}
        <Col className="vCenter tscore" xs={3}>
          {onAir ? (
            <span>On Air</span>
          ) : onEval ? (
            <span>On Eval</span>
          ) : onStandby ? (
            <span>Standby</span>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
