import { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import {
  // checkIcon,
  // lockIcon,
  caretUpIcon,
  caretDownIcon,
} from '../helpers/icons';
import {
  scoreCheckStrict,
  scoreJCheckStrict,
  scoreValid,
  convertPointsToMillipoints,
  convertMillipointsToPoints,
  // scoreTypeToDisplay,
  newestScoreOfType,
  scorePrecisionToDisplay,
  useRoutineScore,
} from '../../utilities/scoring';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import { createScore, useErrorHandler } from '../../apollo/helpers';
import { useEvalConfig, useDisplayConfig } from './hooks';
import { ScoreType } from '../../models';
import { alertActions } from '../../redux/_actions';
import { useHotkeys } from 'react-hotkeys-hook';
import GetFullRoutine from '../../apollo/queries/GetFullRoutine.graphql';
import EvaluatorSlabFinalize from './evaluatorslabfinalize';

const InputComponent = (
  inputRef,
  score,
  type,
  changeHandler,
  keyHandler,
  inputLock,
  precision
) => {
  const { minimumDeduction } = useEvalConfig();

  useEffect(() => {
    if (inputRef && inputRef.current) {
      console.log('focus');
      console.log(score);
      inputRef.current.focus();
      // inputRef.current.select();
    }
  }, [inputLock, inputRef, type, score]);

  console.log(score);

  // const scoreToDisplay = (score && Number(score).toFixed(precision)) || '';
  const scoreToDisplay = score;

  return (
    <input
      id="scoreInputNumpad"
      ref={inputRef}
      inputMode="decimal"
      type="number"
      value={scoreToDisplay}
      // value={score}
      name="score"
      min="0.0"
      max={type === 'SB' ? '0.1' : '10.0'}
      step={type === 'ND' ? '0.1' : minimumDeduction}
      placeholder={type === 'SB' ? '0.1' : ''}
      onChange={changeHandler}
      onKeyPress={keyHandler}
      disabled={inputLock}
      onFocus={(e) => {
        // e.preventDefault();
        e.currentTarget.select();
      }}
    />
  );
};

const FocusInput = () => {
  const inputElement = document.getElementById('scoreInputNumpad');
  if (inputElement) {
    // inputElement.focus();
    console.log('in FocusInput');
    // inputElement.select();
  }
};

const defaultScores = {
  D: '',
  D1: '',
  D2: '',
  E: '',
  E1: '',
  E2: '',
  E3: '',
  E4: '',
  E5: '',
  E6: '',
  SB: '0.1',
  ND: '',
  SV: '10.0',
  SV1: '10.0',
  SV2: '10.0',
  SV3: '10.0',
  SV4: '10.0',
  SV5: '10.0',
  SV6: '10.0',
  F: '10',
  J: '10',
  J1: '10',
  J2: '10',
  J3: '10',
  J4: '10',
  J5: '10',
  J6: '10',
  OPEN: '',
  FINAL: '',
  '-': '-',
};

export function ScoringPanelSlabInput({
  type,
  routineId,
  sessionId,
  score = '10.00',
  setSelectedType,
  isSelected,
}) {
  const displayConfig = useDisplayConfig();
  const precision = scorePrecisionToDisplay(type, displayConfig);
  const internalScore =
    (score &&
      Number(convertMillipointsToPoints(score?.value)).toFixed(precision)) ||
    '';

  return (
    <Row>
      <Col xs={4} className="scoreLabel">
        {type}
      </Col>
      <Col xs={8} className="scoreButton">
        <Button
          className={isSelected ? 'selected' : ''}
          onClick={() => {
            setSelectedType(type);
            FocusInput();
          }}
          variant="secondary-outline"
        >
          {internalScore}
        </Button>
      </Col>
    </Row>
  );
}

export function EvaluatorSlabInput({ type, team, routineId, sessionId }) {
  const userId = useSelector((state) => state.user.profile?.id);
  const { disableInputChecking, minimumDeduction } = useEvalConfig();
  const displayConfig = useDisplayConfig();
  const [inputString, setInputString] = useState('');
  const [inputKey, setInputKey] = useState('');
  const [inputLock, setInputLock] = useState(true);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const routineScore = useRoutineScore();
  const precision = scorePrecisionToDisplay(type, displayConfig);

  const { data, error } = useQuery(GetFullRoutine, {
    variables: { id: routineId },
    skip: !routineId || routineId === 'FAKE-ROUTINE-ID',
  });

  const routine = data?.getRoutine;
  const { scoreTotal, subScoresComplete } = routineScore({
    scores: { items: routine?.scores?.items },
  });

  console.log(scoreTotal);
  const score =
    (routine && newestScoreOfType(routine.scores.items, type)) || null;

  const defaultJScore = displayConfig?.defaultJScore || defaultScores[type];
  const defaultScore = type?.includes('J')
    ? defaultJScore
    : defaultScores[type];

  if (typeof defaultScore === 'undefined') {
    throw new Error(
      `EvaluatorInput: defaultScore for score type=${type} is undefined`
    );
  }

  if (error) {
    throw new Error(error);
  }

  const [internalScore, setInternalScore] = useState(defaultScore); // can be number or string
  const apolloClient = useApolloClient();

  const handleCreateScoreErrors = useErrorHandler(
    'There was a problem submitting the score'
  );

  useEffect(() => {
    console.log(inputLock);
    if (score) {
      setInternalScore(convertMillipointsToPoints(score.value));
      setInputLock(true);
    } else if (['FINAL'].includes(type)) {
      setInternalScore(convertMillipointsToPoints(scoreTotal));
      setInputLock(true);
    } else if (['-'].includes(type)) {
      setInputLock(true);
    } else {
      // needed to reset the indicators / score if changing routines
      setInternalScore(defaultScore);
      setInputLock(false);
    }
  }, [score, setInternalScore, setInputLock, defaultScores, type, routineId]);

  useHotkeys(
    'enter',
    (e) => {
      e.stopPropagation();
      // setInputLock(true);
      //submitScore();
      console.log(typeof internalScore);
      keyHandler(e);
    },
    [inputString, inputKey, setInternalScore, score, internalScore]
  );

  const changeHandler = (e) => {
    const {
      D,
      D1,
      D2,
      E,
      E1,
      E2,
      E3,
      E4,
      E5,
      E6,
      F,
      SB,
      ND,
      SV,
      SV1,
      SV2,
      SV3,
      SV4,
      SV5,
      SV6,
      J,
      J1,
      J2,
      J3,
      J4,
      J5,
      J6,
      OPEN,
    } = ScoreType;

    switch (type) {
      case D:
      case D1:
      case D2:
      case E:
      case E1:
      case E2:
      case E3:
      case E4:
      case E5:
      case E6:
      case SB:
      case ND:
        changeHandlerE(e);
        break;
      case SV:
      case SV1:
      case SV2:
      case SV3:
      case SV4:
      case SV5:
      case SV6:
      case J:
      case J1:
      case J2:
      case J3:
      case J4:
      case J5:
      case J6:
        changeHandlerJ(e);
        break;
      case OPEN:
      case F:
      default:
        passThroughChangeHandler(e);
        break;
    }
  };

  const changeHandlerE = (e) => {
    const val = e.target.value;
    let targetInput = '';

    console.log('value: ', val);
    // console.log('input string: ', inputString)
    // console.log('input key: ', inputKey)

    // Edge cases
    // Scroll up / down to ends
    // Copy and paste random
    // Type input without .
    // Extra same character input
    // Delete doesn't change number
    // Start with period, need to auto add 0
    // Cancel out 'e' & '-' character
    // Scroll down to 0 from nothing yields 0.0
    // Delete 1 from 0.1 yields 0, track key & input

    // Inputs:
    // 10, 100, 10.0
    // Copy paste: 0, 0.0, 234
    // Arrows -> 0, -> 10
    // Delete digits of 1.0

    if (val === '') {
      targetInput = '';
    } else if (val === '1.00' || (val === '10' && inputString === '9.9')) {
      targetInput = '100';
    } else if (inputKey === '' && inputString === '' && val === '0') {
      targetInput = val + '0';
    } else if (
      '0123456789'.includes(val) &&
      Math.abs((Number(val) - Number(inputString)).toFixed(1)) === 0.1
    ) {
      targetInput = val + '0';
    } else if (scoreCheckStrict(val) !== scoreCheckStrict(inputString)) {
      targetInput = val;
    } else {
      targetInput = inputString;
    }

    if (val === '') {
      setInputKey('');
    } // Reset inputKey if value deleted
    setInputString(targetInput);
    const formattedScore = scoreCheckStrict(targetInput);

    // console.log('targetInput: ', targetInput)
    // console.log('scoreCheck: ', formattedScore)
    // console.log('Number(scoreCheck): ', Number(formattedScore));

    if (formattedScore.slice(-2) === '.0' || val[0] === '.') {
      setInternalScore(Number(formattedScore).toFixed(1));
    } else if (formattedScore.slice(-2, -1) === '.') {
      setInternalScore(Number(formattedScore));
    } else {
      setInternalScore(val);
    }
  };

  const changeHandlerJ = (e) => {
    const val = e.target.value;
    let targetInput = '';

    console.log(val);

    if (val === '') {
      targetInput = '';
    } else if (val === '1.00' || (val === '10' && inputString === '9.95')) {
      targetInput = '100';
    } else if (inputKey === '' && inputString === '' && val === '0') {
      targetInput = val + '0';
    } else if (
      '0123456789'.includes(val) &&
      Math.abs((Number(val) - Number(inputString)).toFixed(1)) === 0.1
    ) {
      targetInput = val + '0';
    } else if (scoreJCheckStrict(val) !== scoreJCheckStrict(inputString)) {
      targetInput = val;
    } else {
      targetInput = inputString;
    }

    if (val === '') {
      setInputKey('');
    } // Reset inputKey if value deleted
    setInputString(targetInput);
    const formattedScore = scoreJCheckStrict(targetInput);

    if (formattedScore.slice(-2) === '.0' || val[0] === '.') {
      setInternalScore(Number(formattedScore).toFixed(1));
    } else if (formattedScore.slice(-2, -1) === '.') {
      setInternalScore(Number(formattedScore));
    } else if (formattedScore.slice(-3, -2) === '.') {
      setInternalScore(Number(formattedScore).toFixed(2));
    } else {
      setInternalScore(val);
    }
  };

  const keyHandler = (e) => {
    const exclude = [101, 45]; // Prevent chars: e:101, -:45

    if (exclude.includes(e.charCode)) {
      e.preventDefault();
    }

    if (e.charCode === 13 || e.keyCode === 13) {
      // User presses return/enter
      submitScore();
    }

    setInputKey(e.key);

    /*
    console.log('key: ', e.key)
    const code = e.charCode;
    console.log(code)
    */
  };

  function submitScore(e) {
    if (internalScore === '') {
      return;
    }

    // need to do quick scoreValid check before submitting, override if OPEN scoring
    if (type !== ScoreType.OPEN && !scoreValid(internalScore)) {
      dispatch(
        alertActions.error('Invalid score. Please modify and re-enter.')
      );
      return;
    }

    const input = {
      value: convertPointsToMillipoints(internalScore),
      routineId,
      sessionId,
      type,
      userId,
    };

    // console.log('submitScore');
    // console.log({input})

    createScore(apolloClient, input).catch(handleCreateScoreErrors);
  }

  const passThroughChangeHandler = (e) => {
    setInternalScore(Number(e.target.value));
  };

  const handleNumPad = (val) => {
    const newInput = inputString + val;
    // const newInput = val;
    console.log(newInput);
    FocusInput();
    //changeHandler({ target: { value: Number(newInput) } });
    changeHandler({ target: { value: String(newInput) } });
  };

  console.log(inputLock);

  const BigButton = () => {
    const editButton = (
      <Button
        variant="warning"
        className="vCenter valid"
        disabled={!inputLock}
        onClick={() => {
          setInputLock(false);
          setInputString('');
        }}
      >
        <span>EDIT</span>
      </Button>
    );

    const componentSubmit = (
      <Button
        variant="success"
        className={[
          'vCenter',
          'submit',
          (internalScore !== '' ? scoreValid(internalScore) : false)
            ? 'valid'
            : null,
        ].join(' ')}
        disabled={internalScore !== '' ? !scoreValid(internalScore) : true}
        onClick={submitScore}
      >
        <span>SUBMIT</span>
      </Button>
    );

    const finalSubmit = (
      <EvaluatorSlabFinalize
        scores={routine?.scores?.items || []}
        team={team}
        routineData={routine}
      />
    );

    let selectedButton = null;

    if (type === 'FINAL' && subScoresComplete) {
      selectedButton = finalSubmit;
    } else if (inputLock) {
      selectedButton = editButton;
    } else {
      selectedButton = componentSubmit;
    }

    return selectedButton;
  };

  return (
    <>
      <Row className="scoreLine">
        {/*<div className="inputType">{scoreTypeToDisplay(type)}</div>*/}
        {/*<Col xs={3} className="vCenter">*/}
        {/*{inputLock ? (
            <Button
              variant="light"
              className="vCenter valid"
              onClick={() => {
                setInputLock(false);
                setInputString('');
              }}
            >
              {lockIcon}
            </Button>
          ) : (
            <Button
              variant="light"
              className={[
                'vCenter',
                'submitCheck',
                (internalScore !== '' ? scoreValid(internalScore) : false)
                  ? 'valid'
                  : null,
              ].join(' ')}
              disabled={
                internalScore !== '' ? !scoreValid(internalScore) : true
              }
              onClick={submitScore}
            >
              {checkIcon}
            </Button>
          )}*/}
        {/*</Col>*/}
        <Col xs={12} className="vCenter">
          {InputComponent(
            inputRef,
            internalScore,
            type,
            disableInputChecking ? passThroughChangeHandler : changeHandler,
            keyHandler,
            inputLock,
            precision
          )}
          <div onClick={FocusInput} className="inputLead vCenter">
            {type || '-'}
          </div>
        </Col>
      </Row>
      <Row className="numberPad">
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(1)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>1</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(2)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>2</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(3)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>3</span>
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(4)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>4</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(5)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>5</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(6)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>6</span>
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(7)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>7</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(8)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>8</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(9)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>9</span>
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={(e) => {
                const value = (
                  parseFloat(inputRef.current.value) - minimumDeduction
                ).toFixed(2);
                changeHandler({
                  target: { value },
                });
              }}
              disabled={inputLock}
            >
              {caretDownIcon}
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(0)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>0</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={(e) => {
                const value = (
                  parseFloat(inputRef.current.value) + minimumDeduction
                ).toFixed(2);
                changeHandler({
                  target: { value },
                });
              }}
              disabled={inputLock}
            >
              {caretUpIcon}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="submitLine">
        <Col className="vCenter">
          <BigButton />
        </Col>
      </Row>
    </>
  );
}
