import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Overlay,
  Popover,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ScoreCardLegacy from './scorecardlegacy';
import CompetitorListScore from './competitorlistscore';
import {
  emptyLineup,
  sessionRoleType,
  sessionStatus,
  sessionViewType,
  judgePanelType,
} from '../../redux/_constants';
import {
  producerActions,
  videoPlayerActions,
  alertActions,
} from '../../redux/_actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';
import { sessionActions } from '../../redux/_actions';
import {
  sec2time,
  numToApparatus,
  fullNameToInitialLastName,
} from '../../utilities/conversions';
import { dualTeamBRotation } from '../../utilities/session';
import { playIcon, xIcon, plusIcon } from '../helpers/icons';
import HighlightShadow from '../helpers/highlightshadow';
import { UploadVideoButton, LinkVideoButton } from '../helpers/uploadvideo';
import { CompetitorListScoresheetRow } from './competitorlistscoresheet';
import Hover from '../helpers/hover';
import { nanoid } from 'nanoid';
import { useApolloClient } from '@apollo/client';
import { useMediaInfo } from '../../utilities/clips';
import { RoutineStatus, ClipType } from '../../models';
import {
  createRoutine,
  updateRoutine,
  updateClip,
  useErrorHandler,
} from '../../apollo/helpers';
import './competitorlist.css';
import producerStyles from './producer.module.css';
import { useClips, useRoutines } from '../../utilities/clips';
import {
  useTeamsByAthleteId,
  useAthletesByAthleteId,
  useVideoConfig,
  useEvalConfig,
  useJudgingEnabled,
} from './hooks';
import { first } from 'lodash';
import { displayName } from '../../utilities/conversions';
import { useImage } from '../../utilities/media';
import { SessionContext } from './multiexperimental';

const { ADMIN, JUDGE, PRODUCER } = sessionRoleType;
const { LIVE, POSTGAME, PREGAME } = sessionStatus;

const filterRoster = (roster, lineup, isCompetition = true) => {
  // assuming properly formed roster and lineup
  if (!roster || !lineup) {
    return [];
  }
  if (!isCompetition) {
    return roster;
  }

  return roster.filter((el) => {
    return !lineup.some((i) => i.athleteId === el.id);
  });
};

const routineStatus = (status) => {
  if (status === RoutineStatus.COMPLETE) {
    return <span className="pending">Complete</span>;
  } else if (status === RoutineStatus.ON_AIR) {
    return <span className="pending">On Air</span>;
  } else if (status === RoutineStatus.ON_EVAL) {
    return <span className="pending">On Eval</span>;
  } else if (status === RoutineStatus.STANDBY) {
    return <span className="pending">Standby</span>;
  } else {
    return '--';
  }
};

function CompetitorList({
  team,
  left,
  right,
  max,
  customRound,
  apparatus,
  scoresheet,
  scoresheetScores,
  disabled,
  showHeadshot = true,
  judgeLock = false,
  offset = 0,
  defaultLength = emptyLineup.length,
  isEvalSlab = false,
}) {
  const sessionId = useSelector((state) => state.session.id);
  const producer = useSelector((state) => state.producer);
  const videoPlayer = useSelector((state) => state.videoPlayer);
  const session = useSelector((state) => state.session);
  const [lineup, setLineup] = useState(
    team && team.lineup
      ? team.lineup
      : JSON.parse(JSON.stringify(emptyLineup.slice(0, defaultLength)))
  );
  const [clipping, setClipping] = useState(false);
  const [judging, setJudging] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);
  const { color: lineupTeamColor, home, roster } = team || {};
  const [rosterAlloc, setRosterAlloc] = useState(
    filterRoster(roster, lineup, session.competition)
  );
  const { lineups, sessionTeams } = session.sessionData;
  const dispatch = useDispatch();
  const typeaheadRef = useRef([]);
  const alternating = session.alternating;
  const sessionRound = useSelector((state) => state.producer.round);
  const round = customRound ?? sessionRound;
  const isLeftRightSame = useSelector(
    (state) =>
      state.producer?.teamA?.lineupId === state.producer?.teamB?.lineupId
  );
  const sessionContext = useContext(SessionContext);
  const setEvalRoutine = sessionContext?.setEvalRoutine || null;
  // const setActiveLineup = sessionContext?.setActiveLineup || null;
  // const setActiveApparatus = sessionContext?.setActiveApparatus || null;
  const isExperimental = sessionContext?.isExperimental || false;
  const apparatusIndex =
    alternating &&
    right &&
    [LIVE, PREGAME].includes(session.status) &&
    !isLeftRightSame &&
    !customRound
      ? dualTeamBRotation(round)
      : round;
  const isSoloPlayer = [
    sessionViewType.SOLOPLAYER,
    sessionViewType.DEFAULT,
  ].includes(session.view);
  const isAdmin = session.role === ADMIN;
  const judgingEnabled = useJudgingEnabled();
  const isScoreboardView = session.view === sessionViewType.SCOREBOARD;
  const AorB = left || isSoloPlayer ? 'A' : 'B'; // Note overrriding for SoloPlayer
  const apolloClient = useApolloClient();
  const mediaInfo = useMediaInfo();
  const activeInquiry = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[team.lineupId]
  );
  const videoConfig = useVideoConfig();
  const hasProductionStream = !!videoConfig?.hasProductionStream;
  const evalConfig = useEvalConfig();

  const filteredClips = useClips(team.lineupId, apparatusIndex);
  const filteredRoutines = useRoutines(team.lineupId, apparatusIndex);
  const teamsByAthleteId = useTeamsByAthleteId(sessionId);
  const athletesByAthleteId = useAthletesByAthleteId(sessionId);

  // console.log(teamsByAthleteId);
  const { parseImageFile: image } = useImage();

  const handleCreateRoutineErrors = useErrorHandler(
    'There was a problem creating the routine'
  );
  const handleUpdateRoutineErrors = useErrorHandler(
    'There was a problem updating the routine'
  );
  const handleUpdateClipErrors = useErrorHandler(
    'There was a problem updating the clip'
  );

  const onAirRoutine = filteredRoutines.find(
    (routine) => routine?.status === RoutineStatus.ON_AIR
  );
  // const onEvalRoutine = filteredRoutines.find(
  //   (routine) => routine?.status === RoutineStatus.ON_EVAL
  // );
  const onStandbyRoutine = filteredRoutines.find(
    (routine) => routine?.status === RoutineStatus.STANDBY
  );

  const onEval = filteredRoutines.some(
    (routine) => routine?.status === RoutineStatus.ON_EVAL
  );

  // Updates lineup with rotation change
  useEffect(() => {
    if (team?.lineup) {
      setLineup(JSON.parse(JSON.stringify(team.lineup)));
    }
    return () => {
      setLineup(null);
    };
  }, [team.lineup]);

  // Handles changes in roster, lineup, competition status to filter roster
  useEffect(() => {
    // Need to net out the people on the lineup if competition and not if practice
    setRosterAlloc(filterRoster(roster, lineup, session.competition));
    return () => {
      setRosterAlloc(null);
    };
  }, [roster, lineup, session.competition]);

  useEffect(() => {
    // resets the state of clip editing if clip is turned off
    if (!producer.clip) {
      if (clipping) {
        // undo startTime changes
        setLineup(JSON.parse(JSON.stringify(team.lineup)));
      }

      setFocusIndex(null);
      setClipping(false);
    }
  }, [producer.clip, team.lineup, clipping]);

  useEffect(() => {
    // resets the state of judging if clip is turned off
    if (!producer.judge || !onEval) {
      setFocusIndex(null);
      setJudging(false);
    }
  }, [producer.judge, onEval]);

  const handleClick = (home, index, target = null) => {
    const refId = left ? 'A' : 'B';
    const curRoutine = filteredRoutines[index];

    let items = null;

    if (producer.live) {
      if (curRoutine) {
        // On Air state to close routine or undo to standby
        let targetStatus = RoutineStatus.STANDBY;
        let timestamp = {};

        switch (curRoutine.status) {
          case RoutineStatus.STANDBY:
            if (producer.undo) {
              targetStatus = RoutineStatus.DELETED;
            } else {
              targetStatus = RoutineStatus.ON_AIR;
              timestamp = {
                startedAt: new Date().toISOString(),
              };
            }
            break;
          case RoutineStatus.ON_AIR:
            if (producer.undo) {
              targetStatus = RoutineStatus.STANDBY;
            } else {
              targetStatus = RoutineStatus.ON_EVAL;
              timestamp = {
                completedAt: new Date().toISOString(),
              };
            }
            break;
          case RoutineStatus.ON_EVAL:
            if (producer.undo) {
              targetStatus = RoutineStatus.ON_AIR;
            } else {
              if (!target && isExperimental) {
                setEvalRoutine(curRoutine);
                dispatch(
                  alertActions.notification(
                    `${curRoutine.athlete.name} ${
                      routineStatus(curRoutine.status).props.children
                    } (${curRoutine.apparatus})`
                  )
                );
              }
              if (!target) {
                return;
              }
            }
            break;
          case RoutineStatus.COMPLETE:
            if (producer.undo) {
              targetStatus = RoutineStatus.ON_EVAL;
            } else {
              if (!target) {
                throw new Error(
                  "Shouldn't be able to click on an on-eval routine - update it via finalize in the judging panel"
                );
              }
            }
            break;
          default:
            break;
        }

        // Optimistic Change
        if (producer.undo) {
          dispatch(producerActions.undo());
        }

        // Target override
        if (target) {
          targetStatus = target;
        }

        updateRoutine(apolloClient, filteredRoutines[index], {
          status: targetStatus,
          ...timestamp,
        }).then((res) => {
          // TODO: I believe this action does not do anything meaningful
          //dispatch(producerActions.setEnd(home, filteredRoutines[index].id));
          if (isExperimental) {
            if (targetStatus === RoutineStatus.ON_EVAL) {
              console.log('trying to set the eval routine', {
                routine: res.data.updateRoutine, //filteredRoutines[index],
                res,
              });
              setEvalRoutine(res.data.updateRoutine);
              // setActiveLineup(res.data.updateRoutine.lineupId);
              // setActiveApparatus(
              //   numToApparatus(apparatusIndex, true, session.gender)
              // );}
            } else {
              setEvalRoutine(null);
            }
          }
        }, handleUpdateRoutineErrors);
      } else {
        createRoutine(apolloClient, {
          sessionId,
          lineupId: team.lineupId,
          order: index,
          athleteId: team.lineup[index].athleteId,
          //rotation: round,
          rotation: apparatusIndex,
          status:
            session.judgePanel === judgePanelType.OPEN || isAdmin // isExperimental
              ? target
                ? target
                : RoutineStatus.STANDBY
              : RoutineStatus.ON_AIR,
          startedAt: new Date().toISOString(),
          apparatus: numToApparatus(apparatusIndex, true, session.gender),
        }).catch(handleCreateRoutineErrors);
      }
    } else if (producer.clip) {
      if (!clipping) {
        // Add start time lineup and need to reset endTime
        items = JSON.parse(JSON.stringify(lineup));
        items[index]['startTime'] =
          videoPlayer[`player${isSoloPlayer ? 'A' : refId}`].currentTime;
        items[index]['endTime'] = null;
        setLineup(items);
        setClipping(true);
        setFocusIndex(index);

        // need to create routine if evaluating in postgame only and no routine
        if (!filteredRoutines[index]) {
          createRoutine(apolloClient, {
            sessionId,
            lineupId: team.lineupId,
            order: index,
            athleteId: team.lineup[index].athleteId,
            rotation: round,
            status: RoutineStatus.STANDBY,
            apparatus: numToApparatus(apparatusIndex, true, session.gender),
          }).catch(handleCreateRoutineErrors);
        }
      } else {
        // need to do some error checking here of start/endTimes
        let tempEndTime =
          videoPlayer[`player${isSoloPlayer ? 'A' : refId}`].currentTime;

        // Using redux videoplayer refs
        //let tempEndTime = videoPlayer[`player${id}`].ref?._player.currentTime; // for TheoPlayer
        if (tempEndTime <= lineup[index].startTime) {
          dispatch(alertActions.error('Clip end must be after clip start.'));
          return;
        } else {
          items = lineup;
          items[index]['endTime'] = tempEndTime;
          setClipping(false);
          setFocusIndex(null);

          if (
            filteredClips[index] &&
            filteredClips[index].type === ClipType.FILE
          ) {
            // For Clips
            setLineup(items);
            updateClip(apolloClient, filteredClips[index], {
              seekStartSeconds: items[index]['startTime'],
              seekEndSeconds: items[index]['endTime'],
            }).catch(handleUpdateClipErrors);
          } else {
            // For streams or VODs
            changeLineup(items);
          }
          //dispatch(alertActions.success("Clip saved."));  // or dispatch from redux
        }
      }
    } else if (producer.judge) {
      if (!judging) {
        // start player if it has startTime, if not assign it
        // offline judging

        setJudging(true);
        setFocusIndex(index);
        dispatch(videoPlayerActions.seek(lineup[index].startTime, refId, true)); // for VOD postgame

        // Change status to ON_EVAL
        updateRoutine(apolloClient, filteredRoutines[index], {
          status: RoutineStatus.ON_EVAL,
        }).then(() => {}, handleUpdateRoutineErrors);
      } else {
        // stop player and activate the judging panel, if it has endTime stop player automatically?
        // this is to handle the cancelation of judging mode as eval submit closes out this correctly
        setJudging(false);
        setFocusIndex(null);
        dispatch(alertActions.notification('Evaluation canceled.'));

        // Change status to STANDBY
        updateRoutine(apolloClient, filteredRoutines[index], {
          status: RoutineStatus.STANDBY,
        }).then(() => {}, handleUpdateRoutineErrors);
      }
    } else if (producer.edit) {
      items = JSON.parse(JSON.stringify(lineup));

      if (index === -1) {
        // Add line operation
        const blankAthlete = Object.assign({}, emptyLineup[0]);
        items.push({ ...blankAthlete, key: nanoid() });
      } else {
        // Delete line operation
        items.splice(index, 1);
      }

      //setLineup(items.map((el,i) => { return ({...el, order: i+1})}));
      changeLineup(
        items.map((el, i) => {
          return { ...el, order: i + 1 };
        })
      ); // update order index
    } else {
      // For all VOD operations

      const currentClip = filteredClips[index];
      const hasUnitClip =
        currentClip &&
        [ClipType.FILE, ClipType.YOUTUBE].includes(currentClip.type);
      const hasVODClip =
        currentClip && [ClipType.VOD].includes(currentClip.type);
      const hasLineupClip =
        hasProductionStream &&
        lineup[index].startTime !== null &&
        lineup[index].endTime !== null;
      const startTime = hasLineupClip
        ? lineup[index].startTime
        : hasVODClip
        ? currentClip.seekStartSeconds
        : hasUnitClip
        ? 0
        : null;

      if (!hasLineupClip && (hasUnitClip || hasVODClip)) {
        const fileURL = `${currentClip.originURL}`;
        const clipMeta =
          currentClip?.type === ClipType.FILE
            ? mediaInfo(JSON.parse(currentClip?.other)?.mediainfo)
            : null;
        dispatch(
          videoPlayerActions.load(
            fileURL,
            isSoloPlayer ? 'A' : AorB,
            clipMeta,
            currentClip
          )
        );
      }
      dispatch(
        videoPlayerActions.seek(startTime, isSoloPlayer ? 'A' : AorB, true)
      );
    }
  };

  const changeLineup = (items) => {
    setLineup(items);
    const changeLineupApparatusIndex = apparatusIndex;

    // extra check in case producer was initialized before lineups created
    const lineupId =
      team.lineupId ??
      session.lineups.items.find(
        (el) => sessionTeams.byId[el.sessionTeamId]?.order === (home ? 0 : 1)
      )?.id ??
      null;

    if (!lineupId) {
      dispatch(
        alertActions.error('Lineups not loaded correctly, refresh session.')
      );
      return;
    }

    const prevData = lineups.byId?.[lineupId].lineupData
      ? JSON.parse(lineups.byId?.[lineupId].lineupData)
      : {};
    const newData = { ...prevData, [changeLineupApparatusIndex]: items };

    // should we dispatch a db change here?
    dispatch(
      sessionActions.updateLineup(
        {
          id: lineupId,
          lineupData: JSON.stringify(newData),
          _version: lineups.byId[lineupId]._version,
          order: lineups.byId[lineupId].order,
        },
        changeLineupApparatusIndex
      )
    );
  };

  const handleOnDragEnd = (result) => {
    //console.log("in handleOnDragEnd")
    if (!result.destination) return;

    if (
      filteredRoutines?.[result.destination.index]?.status ===
        RoutineStatus.COMPLETE &&
      !isAdmin
    ) {
      dispatch(
        alertActions.error('Cannot change lineup for completed routines.')
      );
      return;
    }

    if (result.destination.index < filteredRoutines.length && !isAdmin) {
      dispatch(
        alertActions.error('Cannot change lineup with completed routines.')
      );
      return;
    }

    if (result.destination.index === result.source.index) return;

    //const items = Array.from(lineup);
    const items = JSON.parse(JSON.stringify(lineup));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    changeLineup(
      items.map((el, i) => {
        return { ...el, order: i + 1 };
      })
    ); // update order index
  };

  const onKeyDown = (e, index) => {
    e.stopPropagation();
    const ref = typeaheadRef.current[index];

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (ref.items.length > 0) {
        const { name, id, rtnId } = ref.items[0]; // pick top of list
        const items = JSON.parse(JSON.stringify(lineup));
        items[index].name = name;
        items[index].athleteId = id;
        items[index].rtnId = rtnId;
        changeLineup(items);
        ref.state.text = name;
        ref.state.selected = [ref.items[0]];
        ref.hideMenu();
        ref.blur();
        e.preventDefault();
      }

      /* // WIP to advance to next athlete using Tab key
      if (index < typeaheadRef.current.length) {
          if (index === typeaheadRef.current.length - 1) {
            typeaheadRef.current[0].inputNode.select();  
          } else {
            typeaheadRef.current[index + 1].inputNode.select();
          }
        }
        console.log(document.activeElement)
      */
    }
  };

  const scoreField = (athlete, i) => {
    let result = '--';

    // Hidden scores
    if (!producer.scores) {
      return result;
    }

    if (
      filteredRoutines[i] &&
      !producer.judge &&
      !producer.clip &&
      !producer.upload
    ) {
      return (
        <CompetitorListScore
          team={team}
          index={i}
          routine={filteredRoutines[i]}
        />
      );
    } else if (athlete.name.length === 0) {
      return '--';
    } else if (producer.clip) {
      const refId = left ? 'A' : 'B';
      const start = filteredClips[i]?.seekStartSeconds
        ? filteredClips[i].seekStartSeconds
        : lineup[i].startTime;
      const end = filteredClips[i]?.seekEndSeconds
        ? filteredClips[i].seekEndSeconds
        : lineup[i].endTime;

      result = (
        <Button
          className="clipPlay"
          variant="outline-light"
          disabled={!start && !end}
          onClick={() => dispatch(videoPlayerActions.seek(start, refId, true))}
        >
          <span style={{ fontSize: '1rem' }}>
            {`${sec2time(start) ?? '--:--'} / ${sec2time(end) ?? '--:--'}`}
          </span>
        </Button>
      );
    } else if (producer.upload) {
      const createUploadRoutine = async () => {
        return await createRoutine(apolloClient, {
          sessionId,
          lineupId: team.lineupId,
          order: i,
          athleteId: team.lineup[i].athleteId,
          rotation: round,
          status: RoutineStatus.STANDBY,
          apparatus: numToApparatus(apparatusIndex, true, session.gender),
        }).catch(handleCreateRoutineErrors);
      };

      result = (
        <>
          <UploadVideoButton
            createRoutine={createUploadRoutine}
            routine={filteredRoutines[i]}
            clip={filteredClips[i]}
            name={athlete.name}
            round={apparatusIndex}
            position={i + 1}
          />
          <LinkVideoButton
            createRoutine={createUploadRoutine}
            routine={filteredRoutines[i]}
            clip={filteredClips[i]}
            name={athlete.name}
            round={apparatusIndex}
            position={i + 1}
          />
        </>
      );
    } else if (producer.judge) {
      if (judging && i === focusIndex) {
        result = '...';
      } else if (athlete.score) {
        result = athlete.score;
      }
    } else if (producer.edit && athlete.score) {
      result = athlete.score;
    } else {
      // For legacy sessions prior to Apollo scoring
      if (athlete.score) {
        return <ScoreCardLegacy team={team} index={i} lineup={lineup} />;
      }
    }

    return result;
  };

  function getBackground(athlete) {
    const rosterTeam = teamsByAthleteId[athlete.athleteId];

    const color =
      lineupTeamColor ||
      (rosterTeam && first(JSON.parse(rosterTeam.colors))) ||
      'var(--background-haze)';

    return left
      ? `linear-gradient(90deg, ${color} 0% 3%, var(--background-haze) 3% 100%)`
      : `linear-gradient(90deg, var(--background-haze) 0% 97%, ${color} 97% 100%)`;
  }

  const blank = left
    ? `linear-gradient(90deg, var(--background-haze) 0% 3%, var(--background-haze) 3% 100%)`
    : `linear-gradient(90deg, var(--background-haze) 0% 97%, var(--background-haze) 97% 100%)`;

  function NumberButton({
    i,
    athlete = null,
    label = '',
    show = false,
    setShow = () => {},
  }) {
    const target = useRef(null);
    const [statusMsg, setStatusMsg] = useState('');
    let enabled = false;
    let buttonColor = i === -1 ? 'outline-secondary' : 'secondary';
    let blink =
      clipping && focusIndex === i
        ? 'blinkingGreen'
        : judging && focusIndex === i
        ? 'blinkingYellow'
        : null;
    let hoverButton = null;
    let toolTipMsg = 'Play';
    const defaultButton = (
      <span className="lineupNumber"> {label ? label : i + 1 + offset} </span>
    );
    const routine = filteredRoutines[i];

    if (session.status === LIVE) {
      const canControlRoutines =
        producer.live && [ADMIN, PRODUCER, JUDGE].includes(session.role);

      if (!routine) {
        enabled =
          !producer.undo &&
          !!athlete?.name &&
          !onStandbyRoutine &&
          (session.judgePanel === judgePanelType.OPEN || !onAirRoutine) &&
          ((judgeLock && i === filteredRoutines.length) || !judgeLock); // && !onEvalRoutine));
        toolTipMsg = !enabled
          ? null
          : session.judgePanel === judgePanelType.OPEN
          ? 'StandBy'
          : 'Start';
        buttonColor = 'secondary';
      } else {
        if (activeInquiry?.routineId === routine?.id) {
          buttonColor = 'warning';
        } else {
          switch (routine?.status) {
            case RoutineStatus.DELETED:
              enabled = !producer.undo;
              buttonColor = 'secondary';
              break;
            case RoutineStatus.COMPLETE:
              enabled = false;
              if (producer.undo) {
                enabled = true;
              }
              buttonColor = 'danger';
              break;
            case RoutineStatus.ON_AIR:
              enabled = routine?.id !== 'FAKE-ROUTINE-ID';
              if (producer.undo) {
                buttonColor = 'primary';
                toolTipMsg = enabled ? 'Undo to Standby' : null;
              } else {
                buttonColor = 'success';
                toolTipMsg = enabled ? 'End' : null;
              }
              break;
            case RoutineStatus.ON_EVAL:
              if (producer.undo) {
                enabled = true;
                buttonColor = 'primary';
                toolTipMsg = 'Undo to OnAir';
              } else {
                //if (isExperimental) {
                enabled = true;
                //}
                buttonColor = 'warning';
              }
              break;
            case RoutineStatus.STANDBY:
              enabled =
                !!athlete?.name &&
                //(producer.undo || (!onAirRoutine && !onEvalRoutine));
                (producer.undo || !onAirRoutine);
              if (producer.undo) {
                buttonColor = 'primary';
                toolTipMsg = enabled ? 'Undo to Deleted' : null;
              } else {
                buttonColor = 'info';
                toolTipMsg = enabled ? 'Start' : null;
              }
              break;
            default:
              enabled = false;
              toolTipMsg = '???';
          }
        }
      }
      enabled =
        enabled && !activeInquiry?.id && canControlRoutines && !disabled;

      hoverButton = defaultButton;
    } else if (producer.clip) {
      // Button details for VOD mode w/ routine clipping
      if (/*athlete.athleteId && */ !clipping) {
        buttonColor = 'success';
        enabled = true;
        hoverButton = <span className="lineupNumber">S</span>;
        toolTipMsg = 'Clip Start';
      } else if (/*athlete.athleteId && */ clipping && focusIndex === i) {
        buttonColor = 'success';
        enabled = true;
        hoverButton = <span className="lineupNumber">F</span>;
        toolTipMsg = 'Clip Finish';
      }
    } else if (producer.judge) {
      // Button details for VOD mode w/ routine judging, only allowed if clipped
      if (
        /*athlete.athleteId&&*/ !judging &&
        athlete.startTime !== null &&
        routine
      ) {
        buttonColor = 'warning';
        enabled = true;
        hoverButton = <div className="athletePlay">{playIcon}</div>;
        toolTipMsg = 'Play & Judge';
        //toolTipMsg = 'Judge';
      } else if (judging && focusIndex === i && routine) {
        buttonColor = 'warning';
        hoverButton = <div className="athleteX">{xIcon}</div>;
        enabled = true;
        toolTipMsg = 'Cancel';
      } else {
        hoverButton = defaultButton;
      }
    } else {
      // Button details for VOD mode for basic playing
      if (
        (athlete?.startTime !== null &&
          athlete?.startTime !== undefined &&
          athlete?.endTime !== null &&
          athlete?.endTime !== undefined) ||
        (filteredClips[i]?.type === ClipType.VOD &&
          filteredClips[i]?.seekStartSeconds !== null &&
          filteredClips[i]?.seekStartSeconds !== undefined &&
          filteredClips[i]?.seekEndSeconds !== null &&
          filteredClips[i]?.seekEndSeconds !== undefined) ||
        filteredClips[i]?.type === ClipType.FILE ||
        filteredClips[i]?.type === ClipType.YOUTUBE
      ) {
        hoverButton = <div className="athletePlay">{playIcon}</div>;
        enabled = true;
        toolTipMsg = 'Play';
      } else {
        hoverButton = defaultButton;
      }
    }

    // Separate as it can be used in both live and non-live
    if (producer.edit) {
      if (i === -1) {
        // Add blank line button
        hoverButton = <div className="athletePlus">{plusIcon}</div>;
        toolTipMsg = 'Add Position';
      } else {
        // Button details for when editing to add or delete line
        if (i >= filteredRoutines.length || isAdmin) {
          hoverButton = <div className="athleteX">{xIcon}</div>;
          toolTipMsg = 'Delete Position';
        }
      }

      if ((!!!routine && i >= filteredRoutines.length) || i === -1) {
        enabled = true;
      }

      if (isAdmin) {
        enabled = true;
      }
    }

    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={
          <Tooltip
            id="popover-contained"
            className={!enabled || (isAdmin && producer.live) ? 'hidden' : null}
          >
            {toolTipMsg}
          </Tooltip>
        }
        disabled={!enabled}
        transition={false}
      >
        <div
          id="hover-wrap"
          onMouseEnter={() => {
            setShow(true);
          }}
        >
          <Overlay
            target={target.current}
            show={!disabled && show && isAdmin && producer.live}
            placement="top"
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">
                {statusMsg || routineStatus(routine?.status) || 'Select'}
              </Popover.Title>
              <Popover.Content>
                <Button
                  variant="secondary"
                  className="statusSelector"
                  disabled={!routine?.status}
                  onMouseEnter={() => setStatusMsg('--')}
                  onMouseLeave={() => setStatusMsg('')}
                  onClick={() => handleClick(home, i, RoutineStatus.DELETED)}
                ></Button>
                <Button
                  variant="info"
                  className="statusSelector"
                  disabled={routine?.status === RoutineStatus.STANDBY}
                  onMouseEnter={() => setStatusMsg('Standby')}
                  onMouseLeave={() => setStatusMsg('')}
                  onClick={() => handleClick(home, i, RoutineStatus.STANDBY)}
                ></Button>
                <Button
                  variant="success"
                  className="statusSelector"
                  disabled={routine?.status === RoutineStatus.ON_AIR}
                  onMouseEnter={() => setStatusMsg('On Air')}
                  onMouseLeave={() => setStatusMsg('')}
                  onClick={() => handleClick(home, i, RoutineStatus.ON_AIR)}
                ></Button>
                <Button
                  variant="warning"
                  className="statusSelector"
                  disabled={routine?.status === RoutineStatus.ON_EVAL}
                  onMouseEnter={() => setStatusMsg('On Eval')}
                  onMouseLeave={() => setStatusMsg('')}
                  onClick={() => handleClick(home, i, RoutineStatus.ON_EVAL)}
                ></Button>
                <Button
                  variant="danger"
                  className="statusSelector"
                  disabled={routine?.status === RoutineStatus.COMPLETE}
                  onMouseEnter={() => setStatusMsg('Scratch')}
                  onMouseLeave={() => setStatusMsg('')}
                  onClick={() => handleClick(home, i, RoutineStatus.COMPLETE)}
                ></Button>
              </Popover.Content>
            </Popover>
          </Overlay>
          <Button // The Main Number Button Component
            className={[
              focusIndex === i ? blink : null,
              session.status === LIVE &&
              [RoutineStatus.ON_AIR, RoutineStatus.ON_EVAL].includes(
                routine?.status
              ) &&
              !judgingEnabled
                ? 'forceVisible'
                : null,
            ].join(' ')}
            variant={buttonColor}
            disabled={!enabled || disabled}
            onClick={(e) => handleClick(home, i)}
            tabIndex="-1"
            ref={target}
          >
            {!enabled ? (
              defaultButton
            ) : (
              <Hover onHover={hoverButton}>
                {i === -1 ? hoverButton : defaultButton}
              </Hover>
            )}
          </Button>
        </div>
      </OverlayTrigger>
    );
  }

  const addAthlete = () => {
    return (
      <li>
        <Row className="competitorLine">
          <Col xs={2}>
            <div className="buttonContainer">
              <NumberButton i={-1} />
            </div>
          </Col>
          <Col xs={2} />
          <Col xs={5} />
          <Col xs={3} />
        </Row>
      </li>
    );
  };

  function CompetitorLine({ children }) {
    const [show, setShow] = useState(false);

    return (
      <div onMouseLeave={() => setShow(false)}>
        {children({ show, setShow })}
      </div>
    );
  }

  return (
    <div
      className={[
        'competitorList',
        producerStyles.competitorList,
        isEvalSlab ? 'evalSlabCL' : null,
      ].join(' ')}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId={`team${home ? 'A' : 'B'}CompetitorList`}
          isDropDisabled={!producer.edit}
        >
          {(provided) => (
            <ul
              className={`team${home ? 'A' : 'B'}CompetitorList`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {lineup &&
                lineup.map((athlete, i) => {
                  // assign some keys at random to identify draggables, will be used to identify lineup items
                  if (!athlete.key) {
                    athlete.key = nanoid();
                  }

                  // const profileImages =
                  //   athletes.byId[athlete.athleteId]?.profileImg;
                  const profileImages =
                    athletesByAthleteId?.[athlete.athleteId]?.profileImg;
                  const lastImg = image(profileImages);
                  const img = lastImg.filename;
                  const isExhibition =
                    i + 1 >= evalConfig?.exhibitionIndex && !team?.individuals;
                  const exhibitionLabel = isExhibition ? 'EX' : '';

                  const clipIsFileOrLink =
                    filteredClips[i] &&
                    [ClipType.FILE, ClipType.YOUTUBE, ClipType.LINK].includes(
                      filteredClips[i].type
                    );

                  const start =
                    !hasProductionStream && clipIsFileOrLink
                      ? 0
                      : athlete.startTime;
                  const end =
                    !hasProductionStream && clipIsFileOrLink
                      ? filteredClips[i]?.duration
                      : athlete.endTime;

                  const dragDisabled =
                    !producer.edit || (i < filteredRoutines.length && !isAdmin);

                  const isEditDisabled =
                    !producer.edit || (filteredRoutines[i] && !isAdmin);

                  const loadedClip =
                    videoPlayer[`player${AorB}`] &&
                    videoPlayer[`player${AorB}`]?.url &&
                    ((filteredClips[i]?.type === ClipType.FILE &&
                      videoPlayer[`player${AorB}`]?.url?.includes(
                        `${filteredClips[i]?.id}`
                      )) ||
                      ([ClipType.VOD, ClipType.YOUTUBE, ClipType.LINK].includes(
                        filteredClips[i]?.type
                      ) &&
                        videoPlayer[`player${isSoloPlayer ? 'A' : AorB}`]
                          ?.url) === filteredClips[i]?.originURL);

                  // console.log(teamsByAthleteId);
                  // console.log(athlete.name);
                  // console.log(athlete.athleteId);
                  // console.log(athlete);
                  // console.log(teamsByAthleteId[athlete.athleteId]);

                  const overrideTeamName =
                    !producer.edit &&
                    teamsByAthleteId[athlete.athleteId] &&
                    teamsByAthleteId[athlete.athleteId].id !== team.id ? (
                      <label
                        htmlFor={`${athlete.athleteId}_${i}_${apparatusIndex}`}
                        style={{
                          background: `linear-gradient( 270deg, ${
                            JSON.parse(
                              teamsByAthleteId[athlete.athleteId].colors
                            )[0]
                          }, 50%, rgba(255,255,255,0))`,
                        }}
                      >{`${displayName(
                        teamsByAthleteId[athlete.athleteId].name,
                        teamsByAthleteId[athlete.athleteId].altNames
                      )}`}</label>
                    ) : null;

                  return (
                    <Draggable
                      key={`team${home ? 'A' : 'B'}${athlete.key}`}
                      draggableId={`team${home ? 'A' : 'B'}${athlete.key}`}
                      index={i}
                      isDragDisabled={dragDisabled}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <CompetitorLine>
                            {(props) => (
                              <Row
                                className={[
                                  'competitorLine',
                                  producerStyles.competitorLine,
                                  isExhibition ? 'exhibition' : null,
                                ].join(' ')}
                              >
                                {session.status === POSTGAME ? (
                                  <HighlightShadow
                                    start={start}
                                    end={end}
                                    id={AorB}
                                    disabled={
                                      !hasProductionStream && !loadedClip
                                    }
                                  />
                                ) : null}
                                <Col
                                  className={[
                                    'vCenter',
                                    'logo',
                                    producerStyles.logo,
                                  ].join(' ')}
                                  style={{
                                    background: isExhibition
                                      ? null
                                      : getBackground(athlete),
                                  }}
                                  xs={2}
                                >
                                  <div className="buttonContainer">
                                    <NumberButton
                                      i={i}
                                      athlete={athlete}
                                      label={exhibitionLabel}
                                      show={props.show}
                                      setShow={props.setShow}
                                    />
                                  </div>
                                </Col>
                                {showHeadshot ? (
                                  <Col
                                    className={[
                                      'vCenter',
                                      'headshot',
                                      producerStyles.headshot,
                                    ].join(' ')}
                                    xs={2}
                                  >
                                    {athlete.name.length === 0 ||
                                    athlete.athleteId === null ? null : (
                                      <img
                                        src={
                                          img
                                            ? `${MEDIAURL}${img}`
                                            : `${process.env.PUBLIC_URL}/headshotM.svg`
                                        }
                                        alt={
                                          athlete.name === ''
                                            ? ''
                                            : athlete.name + "'s photo"
                                        }
                                      />
                                    )}
                                  </Col>
                                ) : null}
                                <Col
                                  className={[
                                    'vCenter',
                                    'athlete',
                                    producerStyles.athlete,
                                  ].join(' ')}
                                  xs={showHeadshot ? 5 : 7}
                                >
                                  <div
                                    className={[
                                      'athleteName',
                                      isScoreboardView
                                        ? 'athleteScoreboardName'
                                        : null,
                                    ].join(' ')}
                                  >
                                    {isScoreboardView ? (
                                      <p>
                                        {fullNameToInitialLastName(
                                          athlete.name,
                                          false
                                        )}
                                      </p>
                                    ) : (
                                      <>
                                        <p>{athlete.name.split(' ', 2)[0]}</p>
                                        <p>{athlete.name.split(' ', 2)[1]}</p>
                                      </>
                                    )}
                                  </div>
                                  {isScoreboardView ? null : (
                                    <form spellCheck="false">
                                      <Typeahead
                                        onClick={(e, i) => {
                                          e.stopPropagation();
                                        }}
                                        onFocus={(e) => {
                                          e.preventDefault();
                                          e.target.select();
                                        }}
                                        ref={(a) => {
                                          if (a !== null && producer.edit) {
                                            typeaheadRef.current[i] = a;
                                          }
                                        }}
                                        inputProps={{
                                          id: `${athlete.athleteId}_${i}_${apparatusIndex}`,
                                        }}
                                        onChange={(selected, e) => {
                                          const items = JSON.parse(
                                            JSON.stringify(lineup)
                                          );

                                          // No change to selected so returning (onKeyDown override)
                                          if (
                                            selected?.[0]?.id ===
                                            items[i].athleteId
                                          ) {
                                            return;
                                          }

                                          items[i].name =
                                            selected.length === 0 ||
                                            selected[0] === ''
                                              ? ''
                                              : selected[0].name;
                                          items[i].athleteId =
                                            selected.length === 0 ||
                                            selected[0] === ''
                                              ? null
                                              : selected[0].id;
                                          items[i].rtnId =
                                            selected.length === 0 ||
                                            selected[0] === ''
                                              ? ''
                                              : selected[0].rtnId;
                                          changeLineup(items);
                                          // do not access typeaheadRef here because it resets on every render
                                        }}
                                        options={rosterAlloc}
                                        id="athleteTypeahead"
                                        className={[
                                          'typeahead',
                                          producer.edit && !isEditDisabled
                                            ? 'editing'
                                            : '',
                                        ].join(' ')}
                                        disabled={isEditDisabled}
                                        labelKey="name"
                                        clearButton
                                        dropup={true}
                                        flip
                                        placeholder={
                                          producer.edit ? 'Add athlete...' : ''
                                        }
                                        selected={
                                          athlete.athleteId
                                            ? [athlete.name]
                                            : []
                                        }
                                        onKeyDown={(e) => onKeyDown(e, i)}
                                      />
                                    </form>
                                  )}
                                  {overrideTeamName}
                                </Col>
                                <Col
                                  className={[
                                    'vCenter',
                                    'score',
                                    producerStyles.score,
                                  ].join(' ')}
                                  xs={3}
                                >
                                  {scoresheet ? null : scoreField(athlete, i)}
                                </Col>
                                {scoresheet ? (
                                  <CompetitorListScoresheetRow
                                    routine={
                                      scoresheetScores
                                        ? filteredRoutines[i]
                                        : null
                                    }
                                  />
                                ) : null}
                              </Row>
                            )}
                          </CompetitorLine>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
              {producer.edit ? addAthlete() : null}
              {max > lineup?.length &&
                [...Array(max - lineup?.length)].map((x, i) => (
                  <li key={`team${home ? 'A' : 'B'}blank${i + 1}`}>
                    <Row className="competitorLine">
                      {!producer.edit ? (
                        <>
                          <Col
                            className="logo"
                            style={{ background: blank }}
                            xs={2}
                          />
                          <Col className="headshot" xs={2} />
                          <Col className="athlete" xs={5} />
                          <Col className="score" xs={3} />
                        </>
                      ) : null}
                    </Row>
                  </li>
                ))}
              {/* max > lineup.length ? <Row className={'competitorLine'} /> : null*/}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default CompetitorList;
