import { useState } from 'react';
import ScoringPanelSlab from './evaluatorslabscoringpanel'; // DisabledScoringPanelSlab,
import { useSelector } from 'react-redux';
import { RoutineStatus } from '../../models';
// import { useJudgingEnabled } from './hooks';
import { useRoutines } from '../../utilities/clips';
import { Row, Col } from 'react-bootstrap';
import { EvaluatorSlabInput } from './evaluatorslabinput';
import './evaluatorslab.css';

export default function EvaluatorSlab({ round, team, sessionId, judgeType }) {
  // const judgingEnabled = useJudgingEnabled();
  const routines = useRoutines(team.lineupId, round);
  const [selectedType, setSelectedType] = useState('-');

  const inquiry = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[team.lineupId]
  );

  const edit = useSelector(
    (state) => state.evaluator.activeEditByLineupId[team.lineupId]
  );

  const evalRoutine = routines.find(
    (routine) => routine?.status === RoutineStatus.ON_EVAL
  );

  // const airRoutine = routines.find(
  //   (routine) => routine?.status === RoutineStatus.ON_AIR
  // );

  // const standby =
  //   !evalRoutine &&
  //   !airRoutine &&
  //   routines.find(
  //     (routine) => !routine || routine.status === RoutineStatus.STANDBY
  //   );

  // if (judgingEnabled && (inquiry || edit || evalRoutine)) {
  //   return (
  //     <ScoringPanelSlab
  //       team={team}
  //       sessionId={sessionId}
  //       routineId={inquiry?.routineId || edit?.id || evalRoutine?.id}
  //       judgeType={judgeType}
  //     />
  //   );
  // } else {
  //   return (
  //     <DisabledScoringPanelSlab
  //       onAir={airRoutine}
  //       onEval={evalRoutine}
  //       onStandby={standby}
  //       routineId={evalRoutine ? evalRoutine.id : 'FAKE-ROUTINE-ID'}
  //       judgeType={judgeType}
  //     />
  //   );
  // }

  //if (judgingEnabled && (inquiry || edit || evalRoutine)) {
  return (
    <div className="evaluatorSlab">
      <Row>
        <Col className="evaluatorSlabPanel">
          <ScoringPanelSlab
            team={team}
            sessionId={sessionId}
            routineId={inquiry?.routineId || edit?.id || evalRoutine?.id}
            judgeType={judgeType}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
          />
        </Col>
        <Col className="evaluatorSlabInput">
          <EvaluatorSlabInput
            type={selectedType}
            team={team}
            routineId={inquiry?.routineId || edit?.id || evalRoutine?.id}
            sessionId={sessionId}
          />
        </Col>
      </Row>
    </div>
  );
  // } else {
  //   return null;
  // }
}
