/*global Rollbar */
import { useCallback, useContext } from 'react';
import { Row, Col, Button, /*OverlayTrigger,*/ Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { evaluatorActions, alertActions } from '../../redux/_actions';
import {
  caretUpIcon,
  caretDownIcon /* plusIcon, minusIcon */,
} from '../helpers/icons';
import { useApolloClient } from '@apollo/client';
import {
  convertMillipointsToDisplay,
  //scoreTypeToDisplay,
  useRoutineScore,
} from '../../utilities/scoring';
import { RoutineStatus, InquiryStatus } from '../../models';
import {
  updateRoutine,
  updateInquiry,
  useErrorHandler,
} from '../../apollo/helpers';
import { useEvalConfig, useDisplayConfig } from './hooks';
import { SessionContext } from './multiexperimental';

export default function EvaluatorSlabFinalize({ team, scores, routineData }) {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const evalConfig = useEvalConfig();
  const displayConfig = useDisplayConfig();
  const routineScore = useRoutineScore();
  const sessionContext = useContext(SessionContext);
  const setEvalRoutine = sessionContext?.setEvalRoutine || null;

  // console.log(scores);
  // console.log(routineData.scores.items);

  const inquiry = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[team.lineupId]
  );

  const edit = useSelector(
    (state) => state.evaluator.activeEditByLineupId[team.lineupId]
  );

  //const round = useSelector((state) => state.producer.round);

  const handleUpdateInquiryErrors = useErrorHandler(
    'There was a problem updating the inquiry'
  );
  const handleUpdateRoutineErrors = useErrorHandler(
    'There was a problem updating the routine'
  );

  const {
    scoreTotal,
    scoreJAverage,
    scoreJDelta,
    scoreEAverage,
    scoreEDelta,
    scoreND,
    scoreSB,
    subScoresComplete,
    //scoreD,
    scoresD,
    scoreDType,
    scoresJ,
    scoresObj,
    scoresE,
    scoresSV,
  } = routineScore({ scores: { items: scores } });

  // console.log({ scores, scoresSV, scoresJ, scoreTotal, scoreND });

  if (!subScoresComplete) {
    console.error(
      'EvaluatorFinalize is open without complete subscores 😦',
      scores
    );
  }

  //const isFanScore = evalConfig?.fPanel?.length > 0;
  //const isDifficulty = evalConfig?.dPanel?.length > 0;
  const isStickBonus = evalConfig?.stickBonus;
  const isNeutralDeduction = evalConfig?.nDeduct;
  const isExecution = evalConfig?.ePanel?.length > 0;
  const isJudging = evalConfig?.jPanel?.length > 0;
  const isStartValue = !!evalConfig?.startValue;

  const {
    jPrecision,
    avgPrecision,
    svPrecision,
    dPrecision,
    ePrecision,
    scorePrecision,
  } = displayConfig;

  const dScorePrecision = isStartValue ? svPrecision : dPrecision;

  // TODO need to add check for "unchecked" score but still able to finalize

  const submitScore = useCallback(() => {
    //const { score, id, _version, apparatus, order, athlete } = routineData;

    // Debug check here to see if score mismatch
    const {
      scoreTotal: finalScoreCheck,
      scoresJ: judgeScores,
      // scoresD: svScores,
    } = routineScore({
      scores: { items: routineData.scores.items },
    });

    judgeScores.forEach((j, i) => {
      // console.log(j, scoresJ[i]);
      if (j !== scoresJ[i]) {
        Rollbar.error(
          `Score (J${i + 1}) mismatch: ${
            scoresJ[i]
          } attempted vs ${j} recalculated.`,
          {}
        );
      }
    });

    // svScores.forEach((sv, i) => {
    //   if (sv !== scoreD[i]) {
    //     Rollbar.error(
    //       `Score (SV${i + 1}) mismatch: ${
    //         scoreD[i]
    //       } attempted vs ${sv} recalculated.`,
    //       {}
    //     );
    //   }
    // });

    if (finalScoreCheck !== scoreTotal) {
      Rollbar.error(
        `Score total mismatch: ${scoreTotal} attempted vs ${finalScoreCheck} recalculated.`,
        {}
      );
      console.error(
        `Score total mismatch: ${scoreTotal} attempted vs ${finalScoreCheck} recalculated.`
      );
      return dispatch(
        alertActions.error(`Score mismatch: Resubmit score component.`)
      );
    }

    // if (inquiry || edit) {
    //   Rollbar.info(
    //     `Edit/inquiry score for ${athlete.name} on ${apparatus}: ${score} => ${scoreTotal}.`,
    //     {
    //       scoreTotal,
    //       scores,
    //       routineScore: score,
    //       routineId: id,
    //       routineVersion: _version,
    //       round,
    //       apparatus,
    //       order,
    //       name: athlete.name,
    //     }
    //   );
    // }

    const isEdited = routineData.score && routineData.score !== scoreTotal; // might want to make this any change not just manifested final score change
    updateRoutine(apolloClient, routineData, {
      score: scoreTotal,
      isEdited,
      status: RoutineStatus.COMPLETE,
    }).then((res) => {
      if (inquiry) {
        return updateInquiry(apolloClient, inquiry, {
          status: InquiryStatus.CLOSED,
        }).then(() => {
          dispatch(evaluatorActions.clearInquiry(team.lineupId));
        }, handleUpdateInquiryErrors);
      }
      if (edit) {
        return dispatch(evaluatorActions.clearEdit(team.lineupId));
      }
      if (sessionContext) {
        setEvalRoutine(null);
      }
    }, handleUpdateRoutineErrors);
  }, [
    routineData,
    routineData.scores.items.length,
    scoreTotal,
    apolloClient,
    inquiry,
    scores,
    edit,
    dispatch,
    team.lineupId,
    handleUpdateRoutineErrors,
    handleUpdateInquiryErrors,
  ]);

  // console.log(evalConfig);
  // console.log(scoreDType);

  // console.log(routineData);

  const popover = (
    <Popover id="popover-finalize">
      <Popover.Title as="h3">
        <Row style={{ margin: '0', minHeight: '40px' }}>
          <Col xs={6} className="vCenter score">
            Score Card:
          </Col>
          <Col xs={3} className="vCenter score"></Col>
          <Col xs={3} className="vCenter">
            {inquiry ? (
              <span className="inquiryHeader unresolved">Inquiry</span>
            ) : edit ? (
              <span className="inquiryHeader">Edit</span>
            ) : null}
          </Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={12} className="vCenter">
            <span style={{ fontStyle: 'italic', fontWeight: '400' }}>
              {routineData?.athlete?.name}
            </span>
          </Col>
        </Row>
      </Popover.Title>
      <Popover.Content>
        {scoreDType &&
          evalConfig.dPanel.map(({ type, name }, i) => {
            return (
              <Row style={{ margin: '0' }} key={`${routineData.id}_${i}`}>
                <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
                  {/*scoreTypeToDisplay(scoreDType)*/}
                  {name}:
                </Col>
                <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
                  {convertMillipointsToDisplay(scoresD[i], dScorePrecision)}
                </Col>
                <Col xs={3} className="vCenter green"></Col>
              </Row>
            );
          })}
        {isJudging && (
          <>
            <Row style={{ margin: '0' }}>
              <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
                Judging:
              </Col>
              <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
                {scoreJAverage || scoreJAverage === 0
                  ? convertMillipointsToDisplay(scoreJAverage, avgPrecision)
                  : '--'}
              </Col>
              <Col
                xs={3}
                className="vCenter"
                style={{ textAlign: 'center', fontStyle: 'italic' }}
              >
                average
              </Col>
            </Row>
            {evalConfig.jPanel.map(({ type }, i) => {
              const svPanel = evalConfig?.svPanel?.[i];

              return (
                <Row
                  key={`${routineData.id}_${i}`}
                  style={{ margin: '0', padding: '0' }}
                >
                  {svPanel ? (
                    <>
                      <Col
                        xs={2}
                        style={{
                          fontVariantNumeric: 'tabular-nums',
                          paddingRight: '0',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        <span style={{ fontStyle: 'italic' }}>
                          {svPanel.name}:&nbsp;
                        </span>
                      </Col>
                      <Col xs={2}>
                        <span style={{ float: 'right', textAlign: 'right' }}>
                          {scoresSV[i] || scoresSV[i] === 0
                            ? convertMillipointsToDisplay(
                                scoresSV[i],
                                svPrecision
                              )
                            : '--'}
                        </span>
                      </Col>
                    </>
                  ) : null}
                  <Col
                    xs={2}
                    style={{
                      fontVariantNumeric: 'tabular-nums',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                      paddingLeft: '5px',
                      paddingRight: '0',
                    }}
                  >
                    <span style={{ fontStyle: 'italic' }}>{type}:&nbsp;</span>
                  </Col>
                  <Col xs={2}>
                    <span
                      style={{
                        float: 'right',
                        paddingRight: '0',
                        textAlign: 'right',
                      }}
                    >
                      {scoresJ[i] || scoresJ[i] === 0
                        ? convertMillipointsToDisplay(scoresJ[i], jPrecision)
                        : '--'}
                    </span>
                  </Col>
                  {svPanel ? null : <Col xs={4} />}
                  {i === parseInt(evalConfig.jPanel.length / 2) ? (
                    <Col
                      xs={4}
                      className="vCenter"
                      style={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                        marginTop: '-1.8rem',
                      }}
                    >
                      {scoreJDelta && scoreJDelta !== 0
                        ? 'Δ ' +
                          (scoreJDelta || scoreJDelta === 0
                            ? convertMillipointsToDisplay(
                                scoreJDelta,
                                jPrecision
                              )
                            : '--')
                        : null}
                    </Col>
                  ) : (
                    <Col xs={4} />
                  )}
                </Row>
              );
            })}
          </>
        )}
        {isExecution && (
          <>
            <Row style={{ margin: '0' }}>
              <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
                Execution:
              </Col>
              <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
                {scoreEAverage || scoreEAverage === 0
                  ? convertMillipointsToDisplay(scoreEAverage, avgPrecision)
                  : '--'}
              </Col>
              <Col
                xs={3}
                className="vCenter"
                style={{ textAlign: 'center', fontStyle: 'italic' }}
              >
                average
              </Col>
            </Row>
            {evalConfig.ePanel.map(({ type }, i) => {
              return (
                <Row
                  key={`${routineData.id}_${i}`}
                  style={{ margin: '0', padding: '0' }}
                >
                  <Col
                    xs={2}
                    style={{ fontStyle: 'italic', padding: '0 0 0 15px' }}
                  >
                    {type}:
                  </Col>
                  <Col
                    xs={1}
                    className="vCenter"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {scoresObj[type] || scoresObj[type] === 0
                      ? convertMillipointsToDisplay(scoresObj[type], ePrecision)
                      : '--'}
                  </Col>
                  <Col xs={1} style={{ whiteSpace: 'nowrap' }}>
                    (
                    {scoresE[i] || scoresE[i] === 0
                      ? convertMillipointsToDisplay(scoresE[i], ePrecision)
                      : '--'}
                    )
                  </Col>
                  <Col xs={5} />
                  {i === parseInt(evalConfig.ePanel.length / 2) ? (
                    <Col
                      xs={2}
                      className="vCenter"
                      style={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                        marginTop: '-0.8rem',
                      }}
                    >
                      {scoreEDelta !== 0
                        ? 'Δ ' +
                          (scoreEDelta || scoreEDelta === 0
                            ? convertMillipointsToDisplay(
                                scoreEDelta,
                                ePrecision
                              )
                            : '--')
                        : null}
                    </Col>
                  ) : (
                    <Col xs={3} />
                  )}
                </Row>
              );
            })}
          </>
        )}
        {isNeutralDeduction && (
          <Row style={{ margin: '0' }}>
            <Col xs={6} className="vCenter">
              Neutral Deduction:
            </Col>
            <Col xs={3} className="vCenter">
              {scoreND ? convertMillipointsToDisplay(scoreND, 1) : '--'}
            </Col>
            <Col xs={3} className="vCenter red">
              {!!scoreND && caretDownIcon}
            </Col>
          </Row>
        )}
        {isStickBonus && (
          <Row style={{ margin: '0' }}>
            <Col xs={6} className="vCenter">
              Stick Bonus:
            </Col>
            <Col xs={3} className="vCenter">
              {scoreSB ? convertMillipointsToDisplay(scoreSB, 1) : '--'}
            </Col>
            <Col xs={3} className="vCenter green">
              {!!scoreSB && caretUpIcon}
            </Col>
          </Row>
        )}
        <hr style={{ width: '100%' }} />
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Final Score:
          </Col>
          <Col xs={3} className="vCenter score">
            {scoreTotal || scoreTotal === 0
              ? convertMillipointsToDisplay(scoreTotal, scorePrecision)
              : '--'}
          </Col>
          <Col xs={3} className="vCenter">
            <Button
              variant="warning"
              disabled={!subScoresComplete}
              onClick={submitScore}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  console.log(popover);

  return (
    <Button
      className={['vCenter', 'submit'].join(' ')}
      variant="success"
      disabled={!subScoresComplete}
      onClick={submitScore}
    >
      <span>SUBMIT</span>
    </Button>
  );
}
